<template>
  <div>
    <b-overlay :show="loading">

      <b-card>
        <app-collapse>
          <app-collapse-item title="Búsqueda" >
            <b-row>
              <b-col md="6">
                <b-form-group
                >
                  <h5 class="font-weight-bold">
                    Ficha
                  </h5>
                  <b-form-input
                          id="ficha"
                          v-model="filters.num_ficha"
                          placeholder="N° de Ficha"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                >
                  <h5 class="font-weight-bold">
                    Orden de Compra
                  </h5>
                  <b-form-input
                          id="ordenCompra"
                          v-model="filters.orden_compra"
                          placeholder="N° de Orden de Compra"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <div class="text-right">
                  <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="outline-primary"
                          type="submit"
                          class="ml-1"
                          @click="buscar_codigo('danger')"
                  >
                    <feather-icon
                            icon="SearchIcon"
                            class="mr-50"
                    />
                    <span class="align-middle">Buscar</span>

                  </b-button>

                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-card>
      <b-card
          class="text"
          title="Listado Codigo de validez"
      >
        <b-col>
        <b-row>
          <b-col sm="12" md="6"  class="my-1 text-right">
            <div class="d-flex align-items-center mb-1 mb-md-0">
              <label>Mostrar</label>
              <b-form-select
                      id="perPageSelect1"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      class="mx-50 col-md-2"
              ></b-form-select>
              <label>filas por página</label>
            </div>
          </b-col>
          <b-col sm="12" md="6"  class="my-1 text-right">
            <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                    class="ml-1"
                    @click="descargarExcell('danger')"
            >
              <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
              />
              <span class="align-middle">Exportar</span>

            </b-button>
          </b-col>
        </b-row>
        </b-col>
        <div>
          <tabla-codigo-validez :data-value="items"/>
        </div>
        <b-col>
        <b-row>
          <b-col  cols="8" class="mt-3">
            <div v-if="totalRows > 0">
              <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
            </div>
          </b-col>

          <b-col
                  cols="4" class="mt-3"
          >
            <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="my-0"
                    @change="onPageChange"
            />
          </b-col>
        </b-row>
        </b-col>
      </b-card>

    </b-overlay>
  </div>
</template>

<script>
import TablaCodigoValidez from '@/views/codigoValidez/components/TablaCodigoValidez.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  components: {
    'tabla-codigo-validez': TablaCodigoValidez,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filters: {
        num_ficha: null,
        orden_compra: null,
        item_per_page: 50,
        page: 1,
      },
      listaCodigo: [],
      perPage: 50,
      pageOptions: [50, 100, 500, 1000],
      currentPage: 1,

      page: 1,
    }
  },
  mounted() {
  },
  computed: {
    items() {
      this.listaCodigo = this.$store.state.codigoValidez.codigos.data
      if (this.listaCodigo != undefined) {
        return this.listaCodigo.map(item => ({
          nombre: item.nombre,
          apellido: item.apellido,
          rut: item.rut,
          codigo_validez: item.codigo_validez,
          numero_ficha: item.num_ficha,
          orden_compra: item.num_orden_compra,
        }))
      }
    },
    totalRows() {
      return this.$store.state.codigoValidez.rowsTotal;
    },
    to() {
      return this.$store.state.codigoValidez.to;
    },
    from() {
      return this.$store.state.codigoValidez.from;
    },
    loading() {
      return this.$store.state.codigoValidez.loading;
    },
  },
  methods: {
    buscar_codigo(variant = null) {
      if (this.filters.orden_compra !== null || this.filters.num_ficha !== null) {
        this.$store.dispatch('codigoValidez/getCodigoValidez', this.filters)
      } else {
        this.$bvToast.toast('Debes ingresar un dato para la busqueda', {
          title: 'Error',
          variant,
          solid: true,
        })
      }
    },
    onPageChange(page) {
      this.page = page;
      this.buscar_codigo();
    },
    onChangePerPage() {
      this.page = 1;
      this.buscar_codigo();
    },
    descargarExcell(variant = null) {
      if (this.filters.orden_compra !== null || this.filters.num_ficha !== null) {
        this.$store.dispatch('codigoValidez/descargarExcell', this.filters)
        /*this.$bvToast.toast('Excel descargado', {
          title: 'Descarga exitosa ',
          variant,
          solid: true,
        })*/
      } else {
        this.$bvToast.toast('Debes ingresar un dato para descargar el excell', {
          title: 'Error en la busqueda',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>
