<template>


    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="dataValue"
        :fields="fields"
        show-empty
      >
        <template #empty="scope">
          <div class="text-center">No existen resultados</div>
        </template>
      </b-table>
    </b-col>

</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: ['dataValue'],
  data() {
    return {
      perPage: 50,
      pageOptions: [50, 100, 500, 1000],
      totalRows: 1,
      currentPage: 1,

      page: 1,
      fields:
      [
        { key: 'nombre', label: 'Nombre' },
        { key: 'apellido', label: 'Apellido' },
        { key: 'rut', label: 'RUT/DNI' },
        { key: 'codigo_validez', label: 'Codigo Validez' },
        { key: 'numero_ficha', label: 'Numero Ficha' },
        { key: 'orden_compra', label: 'O.C' },
      ],
    }
  },

  mounted() {

  },
}
</script>
